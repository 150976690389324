import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const SaveIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    viewBox="0 0 1024 1024"
    path="M292.571429 877.714286h438.857142v-219.428572H292.571429v219.428572z m512 0h73.142857V365.714286q0-8-5.714286-22t-11.428571-19.714286l-160.571429-160.571429q-5.714286-5.714286-19.428571-11.428571t-22.285715-5.714286v237.714286q0 22.857143-16 38.857143t-38.857143 16H274.285714q-22.857143 0-38.857143-16t-16-38.857143V146.285714H146.285714v731.428572h73.142857v-237.714286q0-22.857143 16-38.857143t38.857143-16h475.428572q22.857143 0 38.857143 16t16 38.857143v237.714286z m-219.428572-530.285715V164.571429q0-7.428571-5.428571-12.857143T566.857143 146.285714H457.142857q-7.428571 0-12.857143 5.428572T438.857143 164.571429v182.857142q0 7.428571 5.428571 12.857143T457.142857 365.714286h109.714286q7.428571 0 12.857143-5.428572t5.428571-12.857143z m365.714286 18.285715v530.285714q0 22.857143-16 38.857143t-38.857143 16H128q-22.857143 0-38.857143-16t-16-38.857143V128q0-22.857143 16-38.857143t38.857143-16h530.285714q22.857143 0 50.285715 11.428572t43.428571 27.428571l160 160q16 16 27.428571 43.428571t11.428572 50.285715z"
  />
);

export default SaveIcon;
