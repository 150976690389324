import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const CopyIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    viewBox="0 0 1024 1024"
    path="M851.025513 737.324862 400.356301 737.324862c-62.219566 0-112.666535-50.444902-112.666535-112.662943L287.689766 174.012196c0-62.217017 50.446969-112.662943 112.666535-112.662943l450.670235 0c62.219566 0 112.666535 50.444902 112.666535 112.662943l0 450.650747C963.692048 686.879959 913.245079 737.324862 851.025513 737.324862zM907.35878 174.012196c0-31.108508-25.223485-56.33096-56.333268-56.33096L400.356301 117.681236c-31.109783 0-56.334291 25.222451-56.334291 56.33096l0 450.650747c0 31.108508 25.223485 56.331983 56.334291 56.331983l450.670235 0c31.10876 0 56.333268-25.222451 56.333268-56.331983L907.359804 174.012196zM118.687916 399.337057 118.687916 849.988828c0 31.107485 25.223485 56.33096 56.333268 56.33096l450.669212 0c31.109783 0 56.334291-25.222451 56.334291-56.33096l0-56.331983 56.333268 0 0 56.331983c0 62.217017-50.446969 112.660896-112.667559 112.660896L175.021184 962.649723c-62.219566 0-112.667559-50.443879-112.667559-112.660896L62.353625 399.337057c0-62.217017 50.446969-112.662943 112.667559-112.662943l56.334291 0 0 56.33096-56.334291 0C143.9114 343.005075 118.687916 368.228549 118.687916 399.337057z"
  />
);

export default CopyIcon;
