import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const LeafIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    viewBox="0 0 1024 1024"
    path="M725.333333 341.333333C341.333333 426.666667 251.733333 689.92 162.986667 910.506667L243.626667 938.666667 284.16 840.533333C304.64 847.786667 325.973333 853.333333 341.333333 853.333333 810.666667 853.333333 938.666667 128 938.666667 128 896 213.333333 597.333333 224 384 266.666667 170.666667 309.333333 85.333333 490.666667 85.333333 576 85.333333 661.333333 160 736 160 736 298.666667 341.333333 725.333333 341.333333 725.333333 341.333333Z"
  />
);

export default LeafIcon;
